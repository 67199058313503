import RailsUjs from '@rails/ujs';
import { loadXeditable } from '../shared/load-dynamic';
import { addClass, removeClass } from '../shared/jquery-alternative';
import { applySlimSelectAutocomplete } from '../shared/slim-select-wrapper';
function update_delivery_options(store_code) {
  fetch(`/stores/${ store_code }/cart/current_delivery_state.json`)
  .then(response => response.json().then(data => {
    addClass('.delivery-state', 'hide');

    if (data.is_delivery_possible) {
      removeClass(".delivery-state.delivery_possible", 'hide');

      addClass(".delivery-state.delivery_possible .reason", 'hide');
      removeClass(`.delivery-state.delivery_possible .reason.${ data.state }`, 'hide');

    } else {
      removeClass(".delivery-state.delivery_not_possible", 'hide');

      addClass(".delivery-state.delivery_not_possible .reason", 'hide');
      removeClass(`.delivery-state.delivery_not_possible .reason.${ data.state }`, 'hide');

      addClass('li.delivery, li.address', 'hide');
      const pickupCheckbox = document.querySelector(".pickup input");
      if(pickupCheckbox) {
        pickupCheckbox.setAttribute('checked', true);
      }
    }

    if (data.is_delivery_option && data.is_delivery_possible) {
      removeClass('li.delivery, li.address', 'hide');
    }
  }))
  .catch(error => {
    document.querySelector('.dynamic_delivery_options').append(`AJAX Error: ${ error }`);
  });
}

function update_line_item_ui(line_item_element, line_item) {
  line_item_element.querySelector('.item-price').textContent = line_item.single_display_amount;
  line_item_element.querySelector('.item-quantity').value = line_item.weighted_quantity;
  line_item_element.querySelector('.item-total').textContent = line_item.display_total;

  if (!!line_item.images && (line_item.images.length > 0)) {
    line_item_element.querySelector('.line-item-variant-image').attributes.href.textContent = line_item.images[0].product_url;
    line_item_element.querySelector('.responsive-img').attributes.src.textContent = line_item.images[0].small_url;
  }
  line_item_element.querySelector('.responsive-img').attributes['alt'] = line_item.display_name;

  // if (!!line_item.display_name) {
  //   const line_item_name_element = line_item_element.querySelector('.item-name');
  //   line_item_name_element.editable('setValue', line_item.display_name);
  //   line_item_name_element.attributes['data-value'] = line_item.display_name;
  //   line_item_name_element.textContent = line_item.display_name;
  // }

  document.querySelector('table.order.summary').querySelector('.item-total').innerHTML = line_item.display_item_total;
  if (parseFloat(line_item.order_adjustment_total > 0)) {
    document.querySelector('table.order.summary').querySelector('.adjustment-total').style.display = '';
    document.querySelector('table.order.summary').querySelector('.adjustment-total').innerHTML = line_item.display_order_adjustment_total;

  }
  if (parseFloat(line_item.order_promo_total > 0)) {
    document.querySelector('table.order.summary').querySelector('.block-order-promo-total').style.display = '';
    document.querySelector('table.order.summary').querySelector('.order-promo-total').innerHTML = line_item.display_order_promo_total;

  }

  document.querySelector('table.order.summary').querySelector('.order-total').innerHTML = line_item.display_order_total;
};

function initializeXEditable() {
  const elements = document.querySelectorAll('#cart .item-name');
  elements.forEach(element => {
    applySlimSelectAutocomplete({
      element,
      url: element.dataset.url,
      formatRecord: (product) => ({
        text: `${ product.name } ${ product.variants[0].options_text } => ${ product.variants[0].display_price }`,
        name: product.name,
        id: product.variants[0].store_variants[0].id,
        price: product.variants[0].price,
        display_price: product.variants[0].display_price,
        currency: product.variants[0].currency
      }),
      events: {
        beforeChange: (newOptions, oldOptions) => {
          fetch(element.dataset.updateUrl, {
            method: 'PUT',
            headers: {
              Accept: 'text/javascript'
            }
          })
          .then(response => response.json().then((line_item) => {
            if (!line_item.errors) {
              const line_item_element = this.closest('tr.line-item');
              update_line_item_ui(line_item_element, line_item);
    
              update_delivery_options(line_item_element.closest('table.order').dataset.storeId);
              return true;
            } else {
              setTimeout(
                () => (alert(JSON.stringify(line_item.errors))), 500
              );

              return false;
            }    
          }))
        }
      }
    })  
  })
  loadXeditable().then(() => {
    $.fn.editable.defaults.mode = 'inline';


    $('#cart .item-name').editable({
      send: 'always',
      type: 'text',
      ajaxOptions: {
        type: 'PUT'
      },
      params(params) {
        const data = {};
        data.line_item_id = this.closest('tr.line-item').dataset.lineItemId;
        data.store_variant_id = params.value;
        data.weighted_quantity = this.closest('tr.line-item').querySelector('.item-quantity').value;
        return data;
      },

      select2: {
        minimumInputLength: 3,
        width: '200px',
        ajax: {
          url() {
            return this.closest('tr.line-item').querySelector('a.item-name').dataset.ajaxUrl;
          },
          dataType: 'json',
          type: 'GET',
          quietMillis: 50,
          cache: true,
          data(term) {
            return {
              term: term.term,
              line_item_id: this.closest('tr.line-item').dataset.lineItemId,
              order_id: this.closest('table.order').dataset.orderId
            };
          },
          results(data, params) {
            const globalData = data.products;
            return {
              results: globalData.map(product => ({
                text: `${ product.name } ${ product.variants[0].options_text } => ${ product.variants[0].display_price }`,
                name: product.name,
                id: product.variants[0].store_variants[0].id,
                price: product.variants[0].price,
                display_price: product.variants[0].display_price,
                currency: product.variants[0].currency
              }))
            };
          }
        },
        //        formatResult: (item) ->
        //          debugger
        //          console.log("HERE 1ST")
        //          item.text
        formatSelection(item) {
          return item.text.split("=>")[0];
        }
      },

      success(line_item, newValue) {
        if (!line_item.errors) {
          const line_item_element = this.closest('tr.line-item');
          setTimeout(() => update_line_item_ui(line_item_element, line_item)
            , 500);

          update_delivery_options(line_item_element.closest('table.order').dataset.storeId);
          return { newValue: line_item.display_name };
        } else {
          return JSON.stringify(line_item.errors);
        }
      }
    });
  });


  document.querySelectorAll('#empty-cart,.empty-cart').forEach(el => {
    el.addEventListener('click', function(e) {
      e.preventDefault();
      const form = this.closest('#update-cart');
      if (!!form.dataset.remote) {
        form.querySelector('input.line_item_quantity').value = '0';
        RailsUjs.fire(form, 'submit');
      } else {
        form.find('input.line_item_quantity').val(0);
        this.closest('#update-cart').submit();
      }
      return true;  
    });
  })

  document.querySelectorAll('#empty-cart,.empty-cart').forEach(el => {
    el.addEventListener('click', function(e) {
      const current_text = this.text;
      this.closest('.nomargin').querySelector('span.select2-chosen').text = current_text;
    });
  })  

  document.querySelectorAll('.line_item_quantity').forEach(elem => {
    elem.addEventListener('change', (e) => {
      e.preventDefault();
      let me = e.target;
      const quantity = me.value;
      const line_item_id = me.dataset['id'];
      const order_number = me.dataset['order'];
      const url = `/api/v2/orders/${ order_number }/line_items/${ line_item_id }.json`;
      if (quantity > 0) {
        fetch(url, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${ Rails.access_token }`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            line_item : {
              weighted_quantity: quantity
            }
          })
        }).then(response => response.json().then(function(line_item) {
          const line_item_element = me.closest('tr.line-item');
          setTimeout(() => update_line_item_ui(line_item_element, line_item)
            , 500);
          let store_code = me.closest('table').dataset.storeId;
          update_delivery_options(store_code);
        }))
        .catch(function(response) { });
      }
    });
  });
}
document.addEventListener('DOMContentLoaded', function() {
  if (document.querySelectorAll('#cart .item-name').length > 0) {
    initializeXEditable();
  }
});
