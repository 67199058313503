import { FormSelect } from "@materializecss/materialize";
import { elementsOnEvent } from "../shared/jquery-alternative";

document.addEventListener('DOMContentLoaded', function() {
  const updateCity = function(main) {
    const state_id = main.querySelector('#user_ship_address_attributes_state_id').value;
    fetch("/api/cities?state_id="+state_id).then(response => response.json().then(data => {
      const select = main.querySelector('#user_ship_address_attributes_city');
      select.innerHTML = '';
      data['cities'].forEach(d => {
        const option = document.createElement("option");
        option.text = d;
        option.value = d;
        select.add(option)
      });
      FormSelect([select], {});
    }));
  };

  elementsOnEvent('.user_ship_address_attributes_country', "change", function(e) {
    const main= e.target.closest('.main');
    const country_id=this.value;
    fetch("/api/states?country_id="+country_id).then(response => response.json().then(data => {
      const select = main.querySelector('#user_ship_address_attributes_state_id');
      select.innerHTML = '';
      const select2 = main.querySelector('#user_ship_address_attributes_city');
      select2.innerHTML = "";
      data['states'].forEach(d => {
        const option = document.createElement("option");
        option.text = d.name;
        option.value = d.id;
        select.add(option)
      });
      FormSelect([select, select2], {})
      updateCity(main);
    }));
  });


  elementsOnEvent('.user_ship_address_attributes_state', "change", function(e) {
    const main = e.target.closest('.main');
    updateCity(main);
  });
});
