export function loadXeditable() {
  return Promise.all([
    import(/* webpackChunkName: "x-editable" */ 'X-editable/dist/bootstrap3-editable/js/bootstrap-editable'),
    import(/* webpackChunkName: "x-editable" */ 'X-editable/dist/bootstrap3-editable/css/bootstrap-editable'),
    import(/* webpackChunkName: "x-editable" */ 'editable/rails.js')
  ]).then(imports => {
    return imports[0].default;
  });
}

// export function loadSelect2() {
//   return Promise.all([
//     import(/* webpackChunkName: "select2" */ 'select2/dist/js/select2.full')
//     // import(/* webpackChunkName: "select2" */ 'select2/dist/css/select2'),
//   ]).then(imports => {
//     return imports[0].default;
//   });
// }
// export function lazySelect2(jQuery, querySelectorString, select2Config) {
//   if (document.querySelector(querySelectorString)) {
//     loadSelect2().then(() => {
//       //copied from spree-select2.js
//       jQuery.fn.select2.defaults.set('width', 'style')
//       jQuery.fn.select2.defaults.set('dropdownAutoWidth', false)
//       jQuery.fn.select2.defaults.set('theme', 'bootstrap4')
      
//       if(jQuery(querySelectorString).parents(".modal").length > 0) {
//         document.querySelectorAll(querySelectorString).forEach(element => {
//           const jqElement = jQuery(element);
//           if(jqElement.parents(".modal").length > 0) {
//             select2Config = {
//               dropdownParent: jqElement.parents(".modal").first(),
//               ...select2Config
//             }
//           } 
//           jqElement.select2(select2Config);
//         });
//       } else {
//         jQuery(querySelectorString).select2(select2Config)
//       }
//     });
//   }
// }

// export function lazyAjaxSelect2(jQuery, querySelectorString) {
//   if (document.querySelector(querySelectorString)) {
//     loadSelect2().then(() => {
//       document.querySelectorAll(querySelectorString).forEach(elem => {
//         jQuery(elem).select2({
//           ajax: elem.dataset.url,
//           dataType: 'json',
//           processResults: function(data) {
//             let collection = elem.dataset.collection ? (data[elem.dataset.collection] || []) : data;
//             let textField = elem.dataset.textField || 'name'
//             let results = collection.map((record) => ({ id: record.id, text: record[textField] }));            
//             return {
//               results: results,
//               pagination: {
//                 more: (data.page * data.per_page) < data.total_count
//               }              
//             };
//           }    
//         });  
//       })
//     });
//   }
// }


// export function loadTagIt() {
//   return Promise.all([
//     import(/* webpackChunkName: "tag-it" */ "jquery-ui-dist/jquery-ui"),

//     import(/* webpackChunkName: "tag-it" */ "tag-it/tag-it"),
//     import(/* webpackChunkName: "tag-it" */ "jquery-ui-dist/jquery-ui.css"),
//     import(
//       /* webpackChunkName: "tag-it" */ "../../../vendor/assets/stylesheets/jquery.tagit.css"
//     ),
//   ]).then((imports) => {
//     return imports[0].default;
//   });
// }
